/* Loading Spinner Container */

.botai-spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	min-height: 35px;
	max-height: 45px;
}

/* Loading Spinner */

.botai-spinner {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: 4px solid #f3f3f3;
	animation: botai-spin 1s linear infinite;
}
  
@keyframes botai-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}