
.botai-chat-body-container {
	height: 100%;
	width: 100%;
	padding-bottom: 16px;
	margin-bottom: 50px;
	overflow-x: hidden;
	overflow-y: scroll;
	touch-action: pan-y;
}

.botai-chat-body-container::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.botai-chat-body-container::-webkit-scrollbar-thumb {
	background-color: #dddddd;
	border-radius: 4px;
}

.botai-chat-body-container::-webkit-scrollbar-thumb:hover {
	background-color: #cfcfcf;
}

.botai-chat-body-container::-webkit-scrollbar-corner {
	background-color: #f1f1f1;
}

/* Messages */

.botai-bot-message-container {
	display: flex;
	flex-direction: row;
}

.botai-user-message-container {
	display: flex;
	flex-direction: row;
	justify-content: right;
}

.botai-bot-message,
.botai-user-message {
	margin-top: 8px;
	padding: 12px 16px;
	min-height: 20px;
	height: fit-content;
	display: flex;
	flex-direction: row;
	width: fit-content;
	font-size: 15px;
 	overflow-wrap: anywhere;
	overflow: hidden;
	white-space: pre-wrap;
}

.botai-bot-message {
	margin-left: 0;
	margin-left: 16px;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	border-bottom-left-radius: 15px;
}

.botai-bot-message-entry {
	animation: bot-entry 0.3s ease-in backwards;
}

@keyframes bot-entry {
	0% {
		transform: translate(-100%, 50%) scale(0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

.botai-user-message {
	margin-right: 0;
	margin-right: 16px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 15px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

.botai-user-message-entry {
	animation: user-entry 0.3s ease-in backwards;
}

@keyframes user-entry {
	0% {
		transform: translate(100%, 50%) scale(0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

.botai-message-bot-avatar,
.botai-message-user-avatar {
	background-size: cover;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	margin-top: 0px;
}

.botai-message-bot-avatar {
	margin-left: 3px;
	margin-right: -15px;
}

.botai-message-user-avatar {
	margin-left: -15px;
	margin-right: 3px;
}

/* Bot Typing Indicator */

.botai-typing-indicator {
	display: flex;
	align-items: center;
}

.botai-dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #ccc;
	margin-right: 4px;
	animation: botai-typing 1s infinite;
}

.botai-dot:nth-child(2) {
  	animation-delay: 0.2s;
	color: #7219ed;
}

.botai-dot:nth-child(3) {
  	animation-delay: 0.4s;
	color: aqua;
}

.botai-dot:nth-child(4) {
	animation-delay: 0.4s;
	color: #e91515;
}

@keyframes botai-typing {
	0% {
		opacity: 0.4;
	}
	50% {
		opacity: 1
	}
	100% {
		opacity: 0.4;
	}
}