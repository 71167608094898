/* Send Button */

.botai-send-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	border: none;
	border-radius: 30%;
	/* box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); */
	cursor: pointer;
	transition: background-color 0.3s ease;
	height: 20px;
	width: 20px;
	margin-left: 3px;
	margin-right: 3px;
}

/* Send Icon */

.botai-send-icon {
	width: 60%;
	height: 60%;
	background-size: cover;
	object-fit: cover;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}