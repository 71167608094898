/* ChatBot Header */

.botai-chat-header-container {
    padding: 12px;
    color: #fff;
    background-color: #2065D1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.botai-chat-header {
    display: flex;
    align-items: center;
}

/* ChatBot Avatar */

.botai-bot-avatar {
    background-size: cover;
	background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 12px;
}

/* Header Title */

.botai-header-title {
    font-size: 18px;
    font-weight: bold;
}

/* Notification & Audio Icon */

.botai-notification-icon-on,
.botai-notification-icon-off,
.botai-audio-icon-on,
.botai-audio-icon-off {
    position: relative;
    display: inline-block;
    background-size: cover;
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}


.botai-notification-icon-off,
.botai-audio-icon-off {
    filter: grayscale(100%);
    opacity: 0.7;
}

.botai-notification-icon-on:hover,
.botai-notification-icon-off:hover,
.botai-audio-icon-on:hover,
.botai-audio-icon-off:hover,
.botai-close-chat-icon:hover {
    opacity: 1;
}

.botai-notification-icon-on::after,
.botai-notification-icon-off::after,
.botai-audio-icon-on::after,
.botai-audio-icon-off::after,
.botai-close-chat-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    opacity: 0;
    transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}
  
.botai-notification-icon-on:hover::after,
.botai-notification-icon-off:hover::after,
.botai-audio-icon-on:hover::after,
.botai-audio-icon-off:hover::after,
.botai-close-chat-icon:hover::after {
    width: 130%;
    height: 130%;
    opacity: 1;
}

/* Close Chat Icon */

.botai-close-chat-icon {
    position: relative;
    display: inline-block;
    background-size: cover;
    width: 30px;
    height: 30px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
}
