/* ChatBot Footer */

.botai-chat-footer-container {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 10px;
	padding-right: 4px;
	border-top: 1px solid #ccc;
	max-height: 55px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	font-size: 10px;
	background-color: #f2f2f2;
	color: #000;
}

.botai-chat-footer {
	display: flex;
	flex-direction: row;
}