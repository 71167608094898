/* Package Globals */

[class^="rcb"] {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Chat Window */

.botai-chat-window {
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: #fff;
    transition: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.botai-window-embedded .botai-chat-window {
    position: static;
    opacity: 1;
    visibility: visible;
    right: auto;
    bottom: auto;
}

.botai-window-open .botai-chat-window {
    opacity: 1;
    visibility: visible;
    animation: expand 0.3s ease-in-out forwards;
}

.botai-window-close .botai-chat-window {
    opacity: 0;
    visibility: hidden;
    animation: collapse 0.3s ease-in-out forwards;
}

@keyframes expand {
    0% {
        transform: translate(100%, 100%) scale(0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
}

@keyframes collapse {
    0% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(100%, 100%) scale(0);
        opacity: 0;
    }
}
