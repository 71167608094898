/* Options Container */

.botai-options-container {
	padding-top: 12px;
	margin-left: 16px;
	max-width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.botai-options-offset {
	margin-left: 50px !important;
}

/* Options */
  
.botai-options {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	border-radius: 20px;
	font-size: 14px;
	border-width: 0.5px;
	border-style: solid;
	cursor: pointer;
	transition: background-color 0.3s ease;
	animation: options-entry 0.5s ease-out;
	overflow: hidden;
}

@keyframes options-entry {
	0% {
	  transform: scale(0);
	  opacity: 0;
	}
	100% {
	  transform: scale(1);
	  opacity: 1;
	}
}

.botai-options:hover {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}