/* Voice Button */

.botai-voice-button-enabled,
.botai-voice-button-disabled {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	border-radius: 50%;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	height: 38px;
	width: 38px;
	margin-left: 8px;
    transition: all 0.3s ease;
    background-color: #fff;
}

.botai-voice-button-enabled {
    border: 1px solid red;
    border-width: 3px;
    box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
}

.botai-voice-button-enabled:hover {
    border: 1px solid rgb(61, 0, 0);
}

.botai-voice-button-disabled {
    border: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
}

.botai-voice-button-disabled:hover {
    box-shadow: 0 0 3px rgb(138, 0, 0, 1);
}

/* Voice Icon */

.botai-voice-icon-on,
.botai-voice-icon-off {
	width: 60%;
	height: 60%;
	background-size: cover;
	object-fit: cover;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.botai-voice-icon-on {
    animation: ping 1s infinite;
}

.botai-voice-icon-off {
    filter: grayscale(100%);
}

@keyframes ping {
    0% {
        filter: brightness(100%);
        opacity: 1;
    }
    50% {
        filter: brightness(50%);
        opacity: 0.8;
    }
}