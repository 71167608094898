/* Chat History Container */

.botai-view-history-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	min-height: 35px;
	max-height: 45px;
}

/* View Chat History Button */

.botai-view-history-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	border-radius: 20px;
	color: #adadad;
	font-size: 12px;
	background-color: #fff;
	border-color: #adadad;
	border-width: 0.5px;
	border-style: solid;
	max-width: 60%;
	cursor: pointer;
	transition: color 0.3s ease, border-color 0.3s ease;
}

.botai-view-history-button > p{
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}