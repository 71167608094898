/* Attachment Button */

.botai-attach-button-enabled,
.botai-attach-button-disabled {
	position: relative;
	display: inline-block;
	background-size: cover;
	width: 30px;
	height: 30px;
	border-radius: 6px;
	text-align: center;
}

.botai-attach-button-enabled input[type="file"],
.botai-attach-button-disabled input[type="file"] {
	position: absolute;
	width: 100%;
	height: 100%;
	display: none;
}

.botai-attach-button-enabled {
	cursor: pointer;
}

.botai-attach-button-disabled {
	opacity: 0.4;
}

.botai-attach-button-enabled::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	opacity: 0;
	transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}

.botai-attach-button-enabled:hover::after {
	width: 130%;
	height: 130%;
	opacity: 1;
}

/* Attachment Icon */

.botai-attach-icon-enabled,
.botai-attach-icon-disabled {
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-top: 2px;
	background-repeat: no-repeat;
	background-size: cover;
	transition: background-image 0.3s ease;
}

.botai-attach-icon-enabled {
	cursor: pointer;
}