/* ChatBot Tooltip */

.botai-chat-tooltip {
	position: absolute;
	padding: 10px;
	/* border-radius: 20px; */
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 5px;
	cursor: pointer;
	font-size: 12px;
	transition: transform 0.3s ease;
}

.botai-chat-tooltip-tail {
	content: "";
	position: absolute;
	top: 50%;
	right: -8px;
	margin-top: -10px;
	border-width: 10px 0 10px 10px;
	border-style: solid;
}

.botai-chat-tooltip.botai-tooltip-hide {
	opacity: 0;
	visibility: hidden;
	animation: tooltip-out 0.5s ease-in-out;
}

.botai-chat-tooltip.botai-tooltip-show {
	opacity: 1;
	visibility: visible;
	animation: tooltip-in 0.5s ease-in-out;
}

@keyframes tooltip-in {
	0% {
	  opacity: 0;
	  transform: translateY(-5px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
@keyframes tooltip-out {
	0% {
	  opacity: 1;
	  transform: translateY(0);
	}
	100% {
	  opacity: 0;
	  transform: translateY(-5px);
	}
}