/* ChatBot Toggle Button */

.botai-toggle-button {
	display: flex;
	flex-direction: row;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
	width: 40px;
	height: 40px;
	border-radius: 20%;
	background-color: #2065d1;
	padding: 10px;
	border: none;
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	background-size: cover;
}

.botai-toggle-button.botai-button-hide {
	opacity: 0;
	visibility: hidden;
	animation: collapse 0.3s ease-in-out forwards;
}

.botai-toggle-button.botai-button-show {
	opacity: 1;
	visibility: visible;
	animation: expand 0.3s ease-in-out forwards;
}

@keyframes expand {
	0% {
		transform: translate(100%, 100%) scale(0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes collapse {
	0% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
	100% {
		transform: translate(100%, 100%) scale(0);
		opacity: 0;
	}
}

/* ChatBot Notification Badge*/

.botai-badge {
	position: absolute;
	top: -6px;
	right: -6px;
	padding: 5px 10px;
	border-radius: 50%;
	background-color: #fe0050;
	color: #ffffff;
}