.botai-chat-input-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 0px;
    background-color: #fff; /* Change background color to white */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for a cleaner look */
    display: flex;
    align-items: center;
    z-index: 1000;
}

.botai-chat-input-textarea {
    flex: 1;
    padding: 10px 10px;
    margin-right: 5px;
    border: 0px solid #ccc;
    border-radius: 10px;
    background-color: #fff; 
    color: #000;
    font-size: 16px;
    resize: none;
    height: auto;
    min-height: 32px;
    overflow-y: auto;
    font-family: 'Trebuchet MS', sans-serif;
}

.botai-chat-input-textarea::placeholder {
    color: #888;
}

.botai-chat-input-textarea:focus {
    outline: none;
    border-color: #2065d1; 
}

.botai-chat-input-textarea::-webkit-scrollbar {
    display: none;
}

.botai-chat-input-button-container {
    display: flex;
    align-items: center;
}

.botai-chat-input-char-counter {
    font-size: 14px;
    color: #bbb;
    margin-left: 8px;
}

.botai-send-button {
    background-color: #2065d1; /* Change background color to #2065d1 */
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.botai-send-button:hover {
    background-color: #104ba3; 
}

.botai-send-button-icon {
    width: 20px;
    height: 20px;
    fill: #fff;
}
