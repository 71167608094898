.botai-message-prompt-container.visible {
    position: sticky;
    bottom: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    animation: popIn 0.3s ease-in-out;
    pointer-events: auto;
}

.botai-message-prompt-container.hidden {
    opacity: 0;
    height: 0px; 
    visibility: hidden;
    pointer-events: none;
}

.botai-message-prompt-text {
    padding: 6px 12px;
    border-radius: 20px;
    color: #adadad;
    font-size: 12px;
    background-color: #fff;
    border: 0.5px solid #adadad;
    cursor: pointer;
    transition: color 0.3s ease, border-color 0.3s ease;
    z-index: 9999;
}

.botai-message-prompt-container.hidden .botai-message-prompt-text {
    padding: 0px;
}

@keyframes popIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    70% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
}
